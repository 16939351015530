import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/SetInitialPassword.module.css";

export default {
  "confirmPasswordFormItem": "SetInitialPassword-module__confirmPasswordFormItem_NGKrpG__",
  "container": "SetInitialPassword-module__container_NGKrpG__",
  "errorAlert": "SetInitialPassword-module__errorAlert_NGKrpG__",
  "form": "SetInitialPassword-module__form_NGKrpG__",
  "instructLogo": "SetInitialPassword-module__instructLogo_NGKrpG__",
  "instructLogoContainer": "SetInitialPassword-module__instructLogoContainer_NGKrpG__",
  "msgContainer": "SetInitialPassword-module__msgContainer_NGKrpG__",
  "resetPasswordContainer": "SetInitialPassword-module__resetPasswordContainer_NGKrpG__",
  "submitBtn": "SetInitialPassword-module__submitBtn_NGKrpG__",
  "termsOfService": "SetInitialPassword-module__termsOfService_NGKrpG__",
  "termsOfServiceContainer": "SetInitialPassword-module__termsOfServiceContainer_NGKrpG__",
  "title": "SetInitialPassword-module__title_NGKrpG__",
  "titleContainer": "SetInitialPassword-module__titleContainer_NGKrpG__"
};
      