import React, { useState } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { Layout, Typography, Row, Col } from 'antd';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';

const { Title } = Typography;
const { Content } = Layout;

import { unitPath } from '../lib/urls';
import VideoPlayer from './VideoPlayer';
import '../../stylesheets/Markdown.css';
import styles from '../../stylesheets/WatchUnit.module.css';

const WatchUnit = () => {
  const { subtopicToken } = useParams();
  const { unit, nextUnit } = useOutletContext();
  const navigate = useNavigate();

  const [showInstructions, setShowInstructions] = useState(false);

  const handleVideoComplete = () => {
    setShowInstructions(true);
  }

  const handleNextUnitClick = () => {
    navigate(unitPath(subtopicToken, nextUnit.token, nextUnit.unit_type));
  }

  const videoUrl = () => {
    const videoGeneration = unit.video_generation;
    return videoGeneration?.presigned_video_url || null;
  }

  const captionsUrl = () => {
    const videoGeneration = unit.video_generation;
    return videoGeneration?.presigned_captions_url || null;
  }

  const watchUnit = unit;

  return (
    <Content>
      <Row>
        <Col 
          span={14}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Title level={3} style={{ marginBottom: '15px' }}>
            {watchUnit.title}
          </Title>
          <VideoPlayer
            handleVideoComplete={handleVideoComplete}
            videoUrl={videoUrl()}
            captionsUrl={captionsUrl()}
            showNextUnitOverlay={nextUnit !== null}
            nextUnitTitle={nextUnit?.title || null}
            handleNextUnitClick={handleNextUnitClick}
          />
        </Col>
        <Col
          span={10}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <div className={styles.rightContainer}>
            <div className={styles.instructionsContainer}>
              {
                showInstructions &&
                <Viewer 
                  initialValue={watchUnit.instructions} 
                />
              }
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default WatchUnit;