import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Layout, 
  Typography, 
  Row, 
  Col, 
  Button,
  Spin,
  Input,
  Radio,
  Tag,
} from 'antd';
const { Content } = Layout;
const { Title } = Typography;
import { 
  PlusOutlined, 
  CheckCircleTwoTone,
  LoadingOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

import EditVideoSegment from './EditVideoSegment';
import VideoPlayer from './VideoPlayer';
import { VideoGenerationStatus } from '../lib/constants';
import { watchUnitPath } from '../lib/urls.js';

import '../../stylesheets/Markdown.css';
import styles from '../../stylesheets/EditWatchUnit.module.css';

const UNSAVED_CHANGES = 'UNSAVED_CHANGES';
const SAVING_CHANGES = 'SAVING_CHANGES';
const ALL_CHANGES_SAVED = 'ALL_CHANGES_SAVED';

const EditWatchUnit = () => {
  const { subtopicToken, unitToken } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  
  const [isInitialLoading, setInitialLoading] = useState(true);
  const [title, setTitle] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [videoGeneration, setVideoGeneration] = useState(null);
  const [instructionalUnitPublished, setInstructionalUnitPublished] = useState(null);
  const [videoSegments, setVideoSegments] = useState([]);
  const [savingState, setSavingState] = useState(ALL_CHANGES_SAVED);
  const [segmentTokensSavingImages, setSegmentTokensSavingImages] = useState([]);
  const [segmentTokensDeletingImages, setSegmentTokensDeletingImages] = useState([]);

  const saveScriptTimer = useRef(null);
  const saveTitleTimer = useRef(null);
  const saveInstructionsTimer = useRef(null);
  let fetchVideoGenerationIntervalId = null;

  useEffect(() => {
    async function fetchWatchUnit() {
      const response = await axios.get(`/api/watch-unit/fetch?token=${unitToken}`);

      const title = response.data.watch_unit.title;
      setTitle(title);

      const instructions = response.data.watch_unit.instructions;
      setInstructions(instructions);
      
      
      const videoGeneration = response.data.watch_unit.video_generation;
      setVideoGeneration(videoGeneration);
      
      const instructionalUnitPublished = response.data.watch_unit.published;
      setInstructionalUnitPublished(instructionalUnitPublished);

      const videoGenerationStatus = videoGeneration?.status || null;

      if ([
        VideoGenerationStatus.GENERATING, 
        VideoGenerationStatus.STORING
      ].includes(videoGenerationStatus)) {
        fetchVideoGenerationIntervalId = setInterval(fetchVideoGeneration, 1000);
      }

      const videoSegments = videoGeneration?.video_segments || [];
      setVideoSegments(videoSegments);
      setInitialLoading(false);
    }

    fetchWatchUnit();
  }, []);

  const handlePreview = () => {
    navigate(watchUnitPath(subtopicToken, unitToken));
  }

  const handleChangeTitle = (event) => {
    setSavingState(UNSAVED_CHANGES);
    if (saveTitleTimer.current) {
      clearTimeout(saveTitleTimer.current);
    }

    saveTitleTimer.current = setTimeout(() => {
      handleSaveTitle(event.target.value);
    }, 1000);
  }

  const handleSaveTitle = async (title) => {
    setSavingState(SAVING_CHANGES);
    await axios.post(
      '/api/watch-unit/update', 
      { token: unitToken, title: title }
    );
    setSavingState(ALL_CHANGES_SAVED);
  };

  const handleChangeInstructions = () => {
    setSavingState(UNSAVED_CHANGES);
    const instance = editorRef.current.getInstance();
    const currentInstructions = instance.getMarkdown();

    if (saveInstructionsTimer.current) {
      clearTimeout(saveInstructionsTimer.current);
    }

    saveInstructionsTimer.current = setTimeout(() => {
      handleSaveInstruction(currentInstructions);
    }, 1000);
  };

  const handleSaveInstruction = async (instructions) => {
    setSavingState(SAVING_CHANGES);
    await axios.post(
      '/api/watch-unit/update', 
      { token: unitToken, instructions: instructions }
    );
    setSavingState(ALL_CHANGES_SAVED);
  };

  const createVideoSegment = async () => {
    setSavingState(SAVING_CHANGES);
    const response = await axios.post('/api/video-segment/create', { watch_unit_token: unitToken });
    setVideoGeneration(response.data.video_generation);
    setVideoSegments(response.data.video_generation.video_segments);
    setSavingState(ALL_CHANGES_SAVED);
  }

  const deleteVideoSegment = async (videoSegmentToken) => {
    setSavingState(SAVING_CHANGES);
    await axios.post('/api/video-segment/delete', { token: videoSegmentToken });
    setSavingState(ALL_CHANGES_SAVED);
    setVideoSegments(prevSegments => 
      prevSegments.filter(videoSegment => videoSegment.token !== videoSegmentToken)
    );
  }

  const handleSaveScript = async (script, videoSegmentToken) => {
    setSavingState(SAVING_CHANGES);
    await axios.post(
      '/api/video-segment/update-script', 
      { script: script, token: videoSegmentToken }
    );
    setSavingState(ALL_CHANGES_SAVED);
  };

  const handleChangeScript = (e, videoSegmentToken) => {
    setSavingState(UNSAVED_CHANGES);
    if (saveScriptTimer.current) {
      clearTimeout(saveScriptTimer.current);
    }

    saveScriptTimer.current = setTimeout(() => {
      handleSaveScript(e.target.value, videoSegmentToken);
    }, 1000);
  };

  const handleBackgroundImageUpload = async (event, videoSegmentToken) => {
    setSegmentTokensSavingImages((prevTokens) => [...prevTokens, videoSegmentToken]);
    setSavingState(SAVING_CHANGES);
    const selectedFile = event.file;

    const formData = new FormData();
    formData.append('background_image', selectedFile);
    formData.append('token', videoSegmentToken);

    const response = await axios.post('/api/video-segment/add-background-image', formData);
    setSegmentTokensSavingImages(prevTokens => 
      prevTokens.filter(prevToken => prevToken !== videoSegmentToken)
    )

    setVideoSegments(prevVideoSegments => 
      prevVideoSegments.map((prevVideoSegment) => {
        if (prevVideoSegment.token === videoSegmentToken) {
          return response.data.video_segment;
        } else {
          return prevVideoSegment;
        }
      })
    )
    setSavingState(ALL_CHANGES_SAVED);
  };

  const handleDeleteSlide = async (videoSegmentToken) => {
    setSegmentTokensDeletingImages((prevTokens) => [...prevTokens, videoSegmentToken]);
    setSavingState(SAVING_CHANGES);
    const response = await axios.post(
      '/api/video-segment/delete-background-image', 
      { token: videoSegmentToken }
    );
    setSegmentTokensDeletingImages(prevTokens => 
      prevTokens.filter(prevToken => prevToken !== videoSegmentToken)
    )

    setVideoSegments(prevVideoSegments => 
      prevVideoSegments.map((prevVideoSegment) => {
        if (prevVideoSegment.token === videoSegmentToken) {
          return response.data.video_segment;
        } else {
          return prevVideoSegment;
        }
      })
    );
    setSavingState(ALL_CHANGES_SAVED);
  }

  const handleChangeInstructionalUnitStatus = async (event) => {
    setSavingState(SAVING_CHANGES);
    await axios.post(
      '/api/watch-unit/update', 
      { token: unitToken, published: event.target.value }
    );
    setSavingState(ALL_CHANGES_SAVED);
  }

  const generateVideo = async () => {
    setVideoGeneration(prevState => ({
      ...prevState,
      status: VideoGenerationStatus.GENERATING
    }));

    await axios.post('/api/video-generation/generate', { token: videoGeneration.token });
    fetchVideoGenerationIntervalId = setInterval(fetchVideoGeneration, 1000);
  }

  const fetchVideoGeneration = async () => {
    const response = await axios.get(`/api/watch-unit/fetch?token=${unitToken}`);
    const videoGeneration = response.data.watch_unit.video_generation;

    if (videoGeneration.status === VideoGenerationStatus.COMPLETE) {
      clearInterval(fetchVideoGenerationIntervalId);
    }

    setVideoGeneration(videoGeneration);
  }

  const displayVideoSegments = () => {
    return videoSegments.map((videoSegment, index) => {
      return(
        <EditVideoSegment
          key={videoSegment.token}
          videoSegment={videoSegment}
          handleBackgroundImageUpload={handleBackgroundImageUpload}
          handleChangeScript={handleChangeScript}
          handleDeleteSlide={handleDeleteSlide}
          deleteVideoSegment={deleteVideoSegment}
          isAddingImage={segmentTokensSavingImages.some(token => token === videoSegment.token)}
          isDeletingImage={segmentTokensDeletingImages.some(token => token === videoSegment.token)}
          index={index}
        />
      )
    });
  }

  const displaySaveStatusIndicator = () => {
    switch (savingState) {
      case SAVING_CHANGES:
        return(
          <div className={styles.saveStatusContainer}>
            <LoadingOutlined className={styles.savingChangesIcon} />
            <span className={styles.savingChangesText}>
              Saving changes...
            </span>
          </div>
        );
      case UNSAVED_CHANGES:
        return(
          <div className={styles.saveStatusContainer}>
            <span className={styles.savingChangesText}>
              Unsaved changes
            </span>
          </div>
        );
      case ALL_CHANGES_SAVED:
        return(
          <div className={styles.saveStatusContainer}>
            <CheckCircleTwoTone 
              twoToneColor='#52C14A'
              className={styles.changesSavedIcon}
            />
            <span className={styles.changesSavedText}>
              All changes saved
            </span>
          </div>
        );
      }
  }

  const videoGeneratingDisplay = () => {
    return(
      <div className={styles.videoGeneratingContainer}>
        <Spin
          indicator={<LoadingOutlined className={styles.loading} spin />}
          className={styles.generatingLoadingSpinner}
        />
        <span className={styles.generatingText}>Video generating...</span>
      </div>
    )
  }

  const displayGenerateVideoButton = () => {
    if (videoGeneration === null) {
      return(
        <Button 
          type='primary'
          size='large'
          className={styles.generateVideoBtn}
          disabled
        >
          Generate Video — 10 min processing
        </Button>
      );
    }

    switch (videoGeneration.status) {
      case VideoGenerationStatus.EDITING:
        return(
          <Button 
            type='primary'
            size='large'
            className={styles.generateVideoBtn}
            loading={savingState === SAVING_CHANGES}
            onClick={generateVideo}
          >
            Generate Video — 10 min processing
          </Button>
        );
      case VideoGenerationStatus.GENERATING:
        return videoGeneratingDisplay();
      case VideoGenerationStatus.STORING:
        return videoGeneratingDisplay();
      case VideoGenerationStatus.COMPLETE:
        return(
          <div>
            <div className={styles.videoPlayerContainer}>
              <VideoPlayer
                handleVideoComplete={() => {}}
                videoUrl={videoGeneration.presigned_video_url}
                captionsUrl={videoGeneration.presigned_captions_url}
                showNextUnitOverlay={false}
                nextUnitTitle={null}
                handleNextUnitClick={() => {}}
              />
            </div>
            <div className={styles.videoGeneratedContainer}>
              <CheckCircleTwoTone 
                twoToneColor='#52C14A'
                className={styles.videoGeneratedIcon}
              />
              <span className={styles.videoGeneratedText}>
                Video generated
              </span>
            </div>
          </div>
        );
    }
  }

  if (isInitialLoading) {
    return null;
  }

  return(
    <Layout className={styles.container}>
      <Content className={styles.mainContent}>
        <Row>
          <Col 
            span={16}
            offset={4}
            style={{ paddingLeft: '32px', paddingRight: '32px' }}
          >
            <div className={styles.saveAndPreviewContainer}>
              {displaySaveStatusIndicator()}
              <Button 
                type='primary'
                size='large'
                onClick={handlePreview}
              >
                Preview
              </Button>
            </div>
            <Title level={3}>
              Edit Instructional Unit
            </Title>
            <Title 
              className={styles.inputLabel}
              level={5}
            >
              Title
            </Title>
            <Input 
              defaultValue={title}
              onChange={handleChangeTitle}
              className={styles.titleInput}
            />
            <div className={styles.instructionalVideoContainer}>
              <Title 
                level={5} 
                className={styles.inputLabel}
              >
                Instructional Video
              </Title>
              <div className={styles.generateVideoInstructions}>
                Review the script and upload the matching slide. Then generate your AI avatar video. You can add multiple slides by adding segments. The segments will be combined into one seamless video.
              </div>
              {displayVideoSegments()}
              <Button 
                type='dashed'
                size='large'
                icon={<PlusOutlined />}
                className={styles.addSegmentBtn}
                onClick={createVideoSegment}
              >
                Add segment
              </Button>
              {displayGenerateVideoButton()}
            </div>
            <Title 
              className={styles.inputLabel}
              level={5}
            >
              Learner Instructions
            </Title>
            <div className={styles.inputDescription}>
              Provide a clear goal and list of steps to complete the task. Be sure each step is explained in the preceding video. These instructions appear after the instructional video and are used to generate feedback in the practice activity.
            </div>
            <div className={styles.learnerInstructionsEditor}>
              <Editor
                ref={editorRef}
                usageStatistics={false}
                initialEditType='wysiwyg'
                height='500px'
                hideModeSwitch={true}
                toolbarItems={[
                  ['heading', 'bold'],
                  ['ul', 'ol', 'table']
                ]}
                initialValue={instructions}
                autofocus={false}
                onChange={handleChangeInstructions}
              />
            </div>
            <Title 
              className={styles.inputLabel}
              level={5}
            >
              State
            </Title>
            <Radio.Group
              size='large'
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
              defaultValue={instructionalUnitPublished}
              onChange={handleChangeInstructionalUnitStatus}
              options={[
                { 
                  value: false,
                  label: (
                    <Tag icon={<EyeInvisibleOutlined />} color='default'>
                      Unpublished
                    </Tag>
                  )
                },
                { 
                  value: true, 
                  label: (
                    <Tag icon={<EyeOutlined />} color='green'>
                      Published
                    </Tag>
                  )
                },
              ]}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default EditWatchUnit;