import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { 
  Layout, 
  Col, 
  Breadcrumb, 
  Typography, 
  Table,
  Tag
} from 'antd';

const { Column } = Table;
const { Content } = Layout;
const { Title } = Typography;

import styles from '../../../stylesheets/managerDashboard/LearnerPracticeSubmissions.module.css';
import { allLearners, practiceSubmissionPath } from '../../lib/urls';

const LearnerPracticeSubmissions = () => {
  const { learnerToken } = useParams();

  const [learner, setLearner] = useState(null);
  const [topics, setTopics] = useState(null);

  useEffect(() => {
    const fetchLearnerPracticeSubmissions = async () => {
      const response = await axios.get(`/api/user/fetch-practice-submissions?user_token=${learnerToken}`);

      setLearner(response.data.user);
      setTopics(response.data.topics);
    }

    fetchLearnerPracticeSubmissions();
  }, []);

  const practiceScenarios = (practiceScenarios) => {
    return practiceScenarios.map((practiceScenario) => {
      return {
        key: practiceScenario.token,
        practiceScenario: practiceScenario.title,
        totalSubmissions: practiceScenario.total_submissions,
        submissions: practiceScenario.submitted_practice_attempts,
      };
    });
  }

  const renderSubmissionTags = (submissions) => {
    if (submissions.length === 0) {
      return '—';
    }

    return submissions.map((submission) => {
      return(
        <Link 
          to={practiceSubmissionPath(learnerToken, submission.token)}
          key={submission.token}
          className={styles.submissionTag}
        >
          <Tag 
            color='green' 
            key={submission.token}
            className={styles.submissionTag}
          >
            {submission.submitted_at}
          </Tag>
        </Link>
      );
    })
  }

  const subtopicComponents = (subtopics) => {
    return subtopics.map((subtopic, index) => {
      return(
        <div 
          className={styles.subtopicContainer}
          key={subtopic.subtopic.token}
        >
          <Title level={5}>{index + 1}. {subtopic.subtopic.title}</Title>
          <Table 
            dataSource={practiceScenarios(subtopic.practice_scenarios)}
            pagination={false}
          >
            <Column 
              title='Practice Scenario' 
              dataIndex='practiceScenario' 
              key='practiceScenario' 
            />
            <Column 
              title='Total Submissions' 
              dataIndex='totalSubmissions' 
              key='totalSubmissions'
            />
            <Column 
              title='Submissions'
              dataIndex='submissions' 
              key='submissions'
              render={renderSubmissionTags}
            />
          </Table>
        </div>
      );
    })
  }

  const topicComponents = () => {
    return topics.map((topic) => {
      return(
        <div 
          className={styles.topicContainer}
          key={topic.topic.token}
        >
          <Title level={3}>{topic.topic.title}</Title>
          {subtopicComponents(topic.subtopics)}
        </div>
      );
    });
  }

  if (learner === null || topics === null) {
    return <div />;
  }

  return(
    <Layout>
      <Content
        className={styles.container}
        align="stretch"
      >
        <Col
          span={18}
          offset={2}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Breadcrumb
            items={[
              {
                title: <Link to={allLearners()}>All Learners</Link>,
              },
              {
                title: learner.display_name,
              },
            ]}
            className={styles.breadcrumbs}
          />
          <div className={styles.learnerNameContainer}>
            <Title level={3}>{learner.display_name}</Title>
          </div>
          <div className={styles.emailContainer}>
            {learner.email}
          </div>
          {topicComponents()}
        </Col>
      </Content>
    </Layout>
  );
};

export default LearnerPracticeSubmissions;