import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/PracticeKeySteps.module.css";

export default {
  "hidden": "PracticeKeySteps-module__hidden_REFxaa__",
  "keyStep": "PracticeKeySteps-module__keyStep_REFxaa__",
  "loadingSpinner": "PracticeKeySteps-module__loadingSpinner_REFxaa__",
  "loadingSpinnerContainer": "PracticeKeySteps-module__loadingSpinnerContainer_REFxaa__",
  "noDisplay": "PracticeKeySteps-module__noDisplay_REFxaa__",
  "overlay": "PracticeKeySteps-module__overlay_REFxaa__",
  "slideContainer": "PracticeKeySteps-module__slideContainer_REFxaa__",
  "slideImage": "PracticeKeySteps-module__slideImage_REFxaa__",
  "yourTurnContainer": "PracticeKeySteps-module__yourTurnContainer_REFxaa__",
  "yourTurnDescription": "PracticeKeySteps-module__yourTurnDescription_REFxaa__",
  "yourTurnTitle": "PracticeKeySteps-module__yourTurnTitle_REFxaa__"
};
      