import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { 
  Typography,
  Button,
  Spin,
  Layout,
  Col,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;
const { Content } = Layout;

import { practiceAttemptPath } from '../lib/urls.js';
import styles from '../../stylesheets/PracticeKeySteps.module.css';

const PracticeKeySteps = () => {
  const { subtopicToken, unitToken, practiceAttemptToken } = useParams();
  const [isLoading, setLoading] = useState(true);

  const { practiceScenario } = useOutletContext();

  const navigate = useNavigate();

  const handleContinueClick = async () => {
    navigate(practiceAttemptPath(subtopicToken, unitToken, practiceAttemptToken));
  }

  const handleImageLoad = () => {
    setLoading(false);
  };

  const loadingSpinnerContainerClass = () => {
    if (isLoading) {
      return styles.loadingSpinnerContainer;
    } else {
      return styles.noDisplay;
    }
  }

  const slideContainerClass = () => {
    if (isLoading) {
      return styles.hidden;
    } else {
      return styles.slideContainer;
    }
  }

  const activeSlide = practiceScenario.practice_slides.find(slide => slide.order === 1);
  const activeSlideUrl = activeSlide ? activeSlide.url : null;

  return(
    <Content>
      <Col span={18}>
        <Title level={3} style={{ marginBottom: '15px' }}>
          {practiceScenario.title}
        </Title>
        <div className={loadingSpinnerContainerClass()}>
          <Spin 
            indicator={<LoadingOutlined className={styles.loadingSpinner} spin />} 
          />
        </div>
        <div className={slideContainerClass()}>
          <img 
            className={styles.slideImage}
            src={activeSlideUrl}
            onLoad={handleImageLoad}
          />
          <div className={styles.overlay}>
            <div className={styles.yourTurnContainer}>
              <Title 
                level={3} 
                id={styles.yourTurnTitle}
              >
                Your turn to practice!
              </Title>
              <ul id={styles.yourTurnDescription}>
                <li className={styles.keyStep}>
                  Practice as many times as you'd like
                </li>
                <li className={styles.keyStep}>
                  Your practice recordings are private unless submitted
                </li>
                <li className={styles.keyStep}>
                  Submitted recordings are only viewed by program managers (and nobody else)
                </li>
              </ul>
              <Button 
                type="primary"
                size="large"
                onClick={handleContinueClick}
                className={styles.continueBtn}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Content>
  );
};

export default PracticeKeySteps;
