import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';

const { Content } = Layout;

import '../../stylesheets/Instructions.css';

const PracticeScenario = () => {
  const { unit, previousUnit, nextUnit } = useOutletContext();

  return (
    <Content>
      <Row>
        <Col 
          span={18}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Outlet 
            context={{
              practiceScenario: unit,
              previousUnit: previousUnit,
              nextUnit: nextUnit,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default PracticeScenario;