import React from 'react';
import { 
  Button, 
  Input, 
  Upload 
} from 'antd';
const { TextArea } = Input;
import { 
  PlusOutlined, 
  LoadingOutlined, 
  DeleteOutlined 
} from '@ant-design/icons';

import styles from '../../stylesheets/EditVideoSegment.module.css';

const EditVideoSegment = (props) => {
  const handleBackgroundImageUpload = (event) => {
    props.handleBackgroundImageUpload(event, props.videoSegment.token)
  }

  const handleChangeScript = (event) => {
    props.handleChangeScript(event, props.videoSegment.token)
  }

  const handleDeleteVideoSegment = () => {
    props.deleteVideoSegment(props.videoSegment.token)
  }

  const handleDeleteSlide = () => {
    props.handleDeleteSlide(props.videoSegment.token)
  }

  const displaySlide = () => {
    if (props.videoSegment.background_image_presigned_url) {
      return(
        <div className={styles.slidePreviewContainer}>
          {
            props.isDeletingImage ?
            <div className={styles.slideDeletingOverlay}>
              <div className={styles.deletingSlideContent}>
                <LoadingOutlined 
                  className={styles.deletingSpinner} 
                />
                <span className={styles.deleteText}>Deleting slide...</span>
              </div>
            </div>
            :
            <div className={styles.slideDeleteOverlay}>
              <div 
                className={styles.deleteSlideContent}
                onClick={handleDeleteSlide}
              >
                <DeleteOutlined className={styles.deleteIcon} />
                <span className={styles.deleteText}>Delete slide</span>
              </div>
            </div>
          }
          <img 
            src={props.videoSegment.background_image_presigned_url}
            className={styles.slidePreview}
          />
        </div>

      );
    } else {
      return(
        <Upload
          accept='.png,.jpeg,.jpg'
          name='background-image'
          listType='picture-card'
          showUploadList={false}
          maxCount={1}
          className={styles.addSlide}
          customRequest={handleBackgroundImageUpload}
        >
          {
            props.isAddingImage ?
              <div>
                <LoadingOutlined />
                <div style={{ marginTop: 8 }}>Adding slide...</div>
              </div>
              :
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Add slide</div>
              </div>
          }
        </Upload>
      );
    }
  }

  return(
    <div className={styles.container}>
      <div className={styles.title}>Segment {props.index + 1}</div>
      <div className={styles.scriptLabel}>
        AI Avatar Script
      </div>
      <div className={styles.contentContainer}>
        <TextArea
          defaultValue={props.videoSegment.script}
          onChange={handleChangeScript}
          className={styles.scriptTextarea}
        />
        {displaySlide()}
      </div>
      <Button 
        className={styles.deleteBtn} 
        type='link'
        onClick={handleDeleteVideoSegment}
      >
        Delete segment
      </Button>
    </div>
  );
}

export default EditVideoSegment;