import { UnitTypes } from '../lib/constants';

export const watchUnitPath = (subtopicToken, unitToken) => {
  return `/subtopic/${subtopicToken}/watch/${unitToken}`;
}

export const practiceScenarioPath = (subtopicToken, unitToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}`
  );
}

export const unitPath = (subtopicToken, unitToken, unitType) => {
  if (unitType == UnitTypes.WATCH_UNIT) {
    return watchUnitPath(subtopicToken, unitToken);
  } else {
    return  practiceScenarioPath(subtopicToken, unitToken);
  }
}

export const practiceVideoPath = (subtopicToken, unitToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}`
  );
}

export const practiceInstructionsPath = (subtopicToken, unitToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}/instructions`
  );
}

export const practiceKeyStepsPath = (subtopicToken, unitToken, practiceAttemptToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}/key-steps/${practiceAttemptToken}`
  );
}

export const practiceAttemptPath = (subtopicToken, unitToken, practiceAttemptToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}/attempt/${practiceAttemptToken}`
  );
}

export const gatheringFeedbackPath = (subtopicToken, unitToken, feedbackToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}/gathering-feedback/${feedbackToken}`
  );
}

export const practiceFeedbackPath = (subtopicToken, unitToken, feedbackToken) => {
  return(
    `/subtopic/${subtopicToken}/practice/${unitToken}/transcript-summary/${feedbackToken}`
  );
}

export const allLearners = () => {
  return '/manager-dashboard';
}

export const learnerPracticeSubmissionsPath = (learnerToken) => {
  return(
    `/manager-dashboard/learner/${learnerToken}`
  );
}

export const practiceSubmissionPath = (learnerToken, practiceAttemptToken) => {
  return(
    `/manager-dashboard/learner/${learnerToken}/attempt/${practiceAttemptToken}`
  );
}