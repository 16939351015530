import React, { useState } from 'react';
import axios from 'axios';
import { Button, Typography } from 'antd';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';
const { Title } = Typography;

import { practiceVideoPath, practiceKeyStepsPath } from '../lib/urls';
import styles from '../../stylesheets/PracticeInstructions.module.css';

const PracticeInstructions = () => {
  const { 
    subtopicToken, 
    unitToken
  } = useParams();
  const navigate = useNavigate();
  const { practiceScenario } = useOutletContext();
  const [isCreatingPracticeAttempt, setIsCreatingPracticeAttempt] = useState(false);

  const handleRewatchVideo = () => {
    navigate(practiceVideoPath(subtopicToken, unitToken));
  }

  const handleContinueClick = async () => {
    setIsCreatingPracticeAttempt(true);
    const response = await axios.post('/api/practice-attempt/create', {
      practice_scenario_token: unitToken,
    });

    const practiceAttemptToken = response.data.practice_attempt.token;

    setIsCreatingPracticeAttempt(false);
    navigate(practiceKeyStepsPath(subtopicToken, unitToken, practiceAttemptToken));
  }

  return(
    <div>
      <Title level={3} style={{ marginBottom: '15px' }}>
        {practiceScenario.title}
      </Title>
      <Viewer 
        initialValue={practiceScenario.instructions}
        key={practiceScenario.instructions}
      />
      <div className={styles.buttonContainer}>
        <Button 
          size="large"
          className={styles.rewatchVideoButton}
          onClick={handleRewatchVideo}
        >
          Rewatch intro video
        </Button>
        <Button 
          type="primary"
          size="large"
          onClick={handleContinueClick}
          loading={isCreatingPracticeAttempt}
        >
          Continue to practice recording
        </Button>
      </div>
    </div>
  );
};

export default PracticeInstructions;