import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/EditVideoSegment.module.css";

export default {
  "addSlide": "EditVideoSegment-module__addSlide_0EaXKW__",
  "container": "EditVideoSegment-module__container_0EaXKW__",
  "contentContainer": "EditVideoSegment-module__contentContainer_0EaXKW__",
  "deleteBtn": "EditVideoSegment-module__deleteBtn_0EaXKW__",
  "deleteIcon": "EditVideoSegment-module__deleteIcon_0EaXKW__",
  "deleteSlideContent": "EditVideoSegment-module__deleteSlideContent_0EaXKW__",
  "deleteText": "EditVideoSegment-module__deleteText_0EaXKW__",
  "deletingSlideContent": "EditVideoSegment-module__deletingSlideContent_0EaXKW__",
  "deletingSpinner": "EditVideoSegment-module__deletingSpinner_0EaXKW__",
  "scriptLabel": "EditVideoSegment-module__scriptLabel_0EaXKW__",
  "scriptTextarea": "EditVideoSegment-module__scriptTextarea_0EaXKW__",
  "slideDeleteOverlay": "EditVideoSegment-module__slideDeleteOverlay_0EaXKW__",
  "slideDeletingOverlay": "EditVideoSegment-module__slideDeletingOverlay_0EaXKW__",
  "slidePreview": "EditVideoSegment-module__slidePreview_0EaXKW__",
  "slidePreviewContainer": "EditVideoSegment-module__slidePreviewContainer_0EaXKW__",
  "title": "EditVideoSegment-module__title_0EaXKW__"
};
      