import React from 'react';
import { PauseOutlined, DeleteOutlined, AudioOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
const { Text } = Typography;
import { RecordingStates } from '../lib/constants';

import styles from '../../stylesheets/RecordingControlRecording.module.css';

const RecordingControlRecording = (props) => {
  const handleSubmit = () => {
    if (props.recordingState == RecordingStates.PAUSED) {
      props.handleSubmitWhilePaused();
    } else {
      props.handleSubmitWhileRecording();
    }
  }

  const handleShowDeleteModal = () => {
    if (props.recordingState !== RecordingStates.SUBMITTING) {
      props.handleShowDeleteModal();
    }
  }

  const handleUnpauseSession = () => {
    if (props.recordingState !== RecordingStates.SUBMITTING) {
      props.handleUnpauseSession();
    }
  }

  const handlePauseSession = () => {
    if (props.recordingState !== RecordingStates.SUBMITTING) {
      props.handlePauseSession();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.deleteContainer}>
        <DeleteOutlined 
          className={styles.deleteButton} 
          onClick={handleShowDeleteModal}
        />
      </div>
      <div className={styles.verticalDivider} />
      <div className={styles.timeElapsedContainer}>
        <Text className={styles.timeElapsedText}>
          {props.elapsedTime}
        </Text>
      </div>
      <div className={styles.pauseContainer}>
        {
          props.recordingState == RecordingStates.PAUSED ? 
          <AudioOutlined 
            className={styles.pause} 
            onClick={handleUnpauseSession}
          />
          :
          <PauseOutlined 
            className={styles.pause} 
            onClick={handlePauseSession}
          />
        }
      </div>
      {
        props.recordingState == RecordingStates.SUBMITTING ?
        <Button 
          type='primary'
          className={styles.submitButton}
          loading
          iconPosition='end'
        >
          Finishing
        </Button>
        :
        <Button 
          type='primary'
          className={styles.submitButton}
          onClick={handleSubmit}
        >
          Finish
        </Button>
      }
    </div>
  );
};

export default RecordingControlRecording;