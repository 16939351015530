class Navigator {
  previousUnit(orderedUnits, currentUnitToken) {
    const targetIndex = orderedUnits.findIndex(unit => unit.token === currentUnitToken);

    if (targetIndex === -1) {
      return null;
    }

    return this.unitAtIndex(orderedUnits, targetIndex - 1);
  }

  nextUnit(orderedUnits, currentUnitToken) {
    const targetIndex = orderedUnits.findIndex(unit => unit.token === currentUnitToken);

    if (targetIndex === -1) {
      return null;
    }

    return this.unitAtIndex(orderedUnits, targetIndex + 1);
  }

  unitAtIndex(orderedUnits, targetIndex) {
    if (targetIndex < 0) {
      return null;
    } else if(targetIndex >= orderedUnits.length) {
      return null;
    } else {
      const unit = orderedUnits[targetIndex];

      return {
        unit_type: unit.unit_type,
        token: unit.token,
        title: unit.title,
      }
    }
  }
}

export default Navigator;