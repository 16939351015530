import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Input, Button, Alert } from 'antd';
import axios from 'axios';
const { Title, Text } = Typography;

import { 
  PasswordManagerFetchUserApiStatusCodes, 
  SetInitialPasswordApiStatusCodes 
} from '../lib/constants';
import styles from '../../stylesheets/SetInitialPassword.module.css';

const SetInitialPassword = () => {
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const [isResetting, setSettingPassword] = useState(false);
  const [commonState, setCommonState] = useState({
    isLoading: true,
    user: null,
    hasError: false,
    errorMessage: null
  })

  useEffect(() => {
    const fetchUser = async () => {
      const urlParams = new URLSearchParams(location.search);
      const response = await axios.get(`/api/password-manager/fetch-user?token=${urlParams.get('token')}`);

      switch(response.data.status) {
        case PasswordManagerFetchUserApiStatusCodes.INVALID_TOKEN:
          setCommonState({
            isLoading: false,
            user: null,
            hasError: true,
            errorMessage: 'Token is invalid.'
          })
          break;
        case PasswordManagerFetchUserApiStatusCodes.SUCCESS:
          setCommonState({
            isLoading: false,
            user: response.data.user,
            hasError: false,
            errorMessage: null
          })
          break;
      }
    }

    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }

    fetchUser();
  }, []);

  const handleSetPassword = async (items) => {
    setSettingPassword(true);

    const urlParams = new URLSearchParams(location.search);

    const params = {
      new_password: items.newPassword,
      new_password_confirmation: items.newPasswordConfirmation,
      reset_password_token: urlParams.get('token'),
    };

    const response = await axios.post('/api/password-manager/set-initial-password', params);

    setSettingPassword(false);
    switch(response.data.status) {
      case SetInitialPasswordApiStatusCodes.PASSWORD_TOO_SHORT:
        setCommonState(prevState => ({
          ...prevState,
          hasError: true,
          errorMessage: 'Password must be at least 8 characters.',
        }));
        break;
      case SetInitialPasswordApiStatusCodes.PASSWORDS_DO_NOT_MATCH:
        setCommonState(prevState => ({
          ...prevState,
          hasError: true,
          errorMessage: 'Passwords do not match.',
        }));
        break;
      case SetInitialPasswordApiStatusCodes.INVALID_TOKEN:
        setCommonState(prevState => ({
          ...prevState,
          hasError: true,
          errorMessage: 'Token is invalid.',
        }));
        break;
      case SetInitialPasswordApiStatusCodes.SUCCESS:
        setCommonState(prevState => ({
          ...prevState,
          hasError: false,
          errorMessage: null,
        }));
        navigate('/');
        break;
    }
  }

  const emailAddress = () => {
    if (commonState.user === null) {
      return '';
    } else {
      return commonState.user.email;
    }
  }

  if (commonState.isLoading) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.resetPasswordContainer}>
      {
        commonState.hasError &&
          <Alert 
            type='error'
            message={commonState.errorMessage}
            className={styles.errorAlert}
          />
        }
        <div className={styles.instructLogoContainer}>
          <img
            src='https://development-instruct.s3.us-east-2.amazonaws.com/instruct-logo.jpg'
            className={styles.instructLogo}
          />
        </div>
        <div className={styles.titleContainer}>
          <Title level={2} className={styles.title}>
            Create your password
          </Title>
        </div>
        <div className={styles.msgContainer}>
          <Text>
            To get started, set a password with at least 8 characters.
          </Text>
        </div>

        <Form 
          layout='vertical'
          className={styles.form}
          onFinish={handleSetPassword}
          requiredMark={false}
          initialValues={{
            email: emailAddress(),
          }}
        >
          <Form.Item
            label='Email'
            name='email'
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label='Password'
            name='newPassword'
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.Password ref={passwordInputRef} />
          </Form.Item>

          <Form.Item
            label='Confirm password'
            name='newPasswordConfirmation'
            rules={[
              { 
                required: true,
                message: '',
              }
            ]}
            className={styles.confirmPasswordFormItem}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button 
              size='large'
              htmlType='submit'
              type='primary'
              className={styles.submitBtn}
              loading={isResetting}
            >
              Set password and log in
            </Button>
          </Form.Item>
        </Form>

        <div className={styles.termsOfServiceContainer}>
          <Text className={styles.termsOfService}>
            By continuing, you agree to the <Link to='/terms-of-service'>Instruct Terms of Service</Link>.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SetInitialPassword;