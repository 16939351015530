import React from 'react';
import { Modal, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
const { Text } = Typography;

import styles from '../../stylesheets/PracticeSubmitModal.module.css';
import { PracticeSubmitModalState } from '../lib/constants';

const PracticeSubmitModal = (props) => {
  switch (props.modalState) {
    case PracticeSubmitModalState.SHOW_SUBMIT_OPTION:
      return(
        <Modal
          title='Nice work! Ready to submit?'
          open={props.modalState === PracticeSubmitModalState.SHOW_SUBMIT_OPTION}
          closeable={true}
          onOk={props.handleSubmitPracticeSession}
          okText="Yes, let's submit it"
          onCancel={props.handleHideModal}
          okButtonProps={{
            loading: props.submittingPracticeSession,
          }}
        >
          <div className={styles.bodyContainer1}>
            <Text>Your practice recording and feedback will be shared privately with a few select admins. Don’t worry—other learners won’t see it.</Text>
          </div>
          <div className={styles.bodyContainer2}>
            <Text>This practice activity will be marked as complete, but you’re welcome to practice again and submit more recordings.</Text>
          </div>
        </Modal>
      );
    case PracticeSubmitModalState.SHOW_SUBMIT_CONFIRMATION:
      return(
        <Modal
          open={props.modalState === PracticeSubmitModalState.SHOW_SUBMIT_CONFIRMATION}
          closeable={true}
          onOk={props.handleNextUnit}
          okText='Next unit'
          okButtonProps={{
            disabled: props.nextUnitBtnDisabled
          }}
          onCancel={props.handleHideModal}
        >
          <div className={styles.submitConfirmationContainer}>
            <CheckCircleFilled 
              className={styles.checkMark} 
            />
            <Text className={styles.submitConfirmationMsg}>
              Practice Recording Submitted
            </Text>
          </div>
        </Modal>
      );
  }
};

export default PracticeSubmitModal;